const scrollStops = [
  23.6, 35.8, 51.6, 67.3, 75.1, 91, 100
];

// Catch clicks on the scroll down arrow to
// scroll through the infographic step by step
document.addEventListener('click', (event) => {
  if (!event.target.matches('.arrow')) {
    return;
  }

  event.preventDefault();

  const curr = calcCurrScrollPerc();
  const next = scrollStops.find(scrollStop => scrollStop > curr);

  window.scrollTo({
    top: calcScrollTarget(next),
    left: 0,
    behavior: "smooth"
  })
}, false);

/**
 * Calculates how many percent of the infographic have been scrolled,
 * rounded up.
 *
 * @returns {number} The current scroll percentage
 */
function calcCurrScrollPerc() {
  return Math.ceil((window.scrollY + window.innerHeight) / document.body.clientHeight * 100);
}

/**
 * Given a scroll percentage, calculates a scroll position in pixels.
 *
 * @param scrollPerc {number} A scroll percentage
 * @returns {number} The scroll position in pixels
 */
function calcScrollTarget(scrollPerc) {
  return document.body.clientHeight * (scrollPerc / 100) - window.innerHeight;
}
